import { useEffect, useState } from 'react';
import {
  ConsentDetails as Details,
  ConsentResponse,
  DataHolder,
  Logger,
  RevokeDialog,
  Status,
} from '@adatree-oss/atomic-components';
import { Box, Skeleton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { APP_SETTINGS } from '../../../app/settings/app.settings';
import { URL_SETTINGS } from '../../../app/settings/url.settings';
import consentRepository from '../../../app/api/repositories/consent-repository';

type ConsentDetailsParams = {
  consentId: string;
};

export const ConsentDetails = () => {
  const { consentId } = useParams<ConsentDetailsParams>();
  const pastUseCasetTitle = 'Data we received';
  const presentUseCasetTitle = 'Data we are currently receiving';
  const [consent, setConsent] = useState<ConsentResponse>();
  const [dataHolders, setDataHolder] = useState<DataHolder[]>();
  const [dataHolderLogoUrl, setDataHolderLogoUrl] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [isProcessingRevoke, setIsProcessingRevoke] = useState<boolean>(false);
  const [useCasetTitle, setUseCasetTitle] = useState<string>(presentUseCasetTitle);

  useEffect(() => {
    consentRepository.findConsentById(consentId).then((foundConsent: ConsentResponse) => {
      setConsent(Object.assign({}, foundConsent));
      if (foundConsent.status === Status.EXPIRED || foundConsent.status === Status.REVOKED) {
        setUseCasetTitle(pastUseCasetTitle);
      }
      setIsLoading(false);
    });
  }, [consentId]);

  useEffect(() => {
    consentRepository.findAllDataHolders().then((dataHolders: DataHolder[]) => {
      setDataHolder(dataHolders);
    });
  }, []);

  useEffect(() => {
    if (dataHolders && consent) {
      dataHolders.forEach((dataHolder) => {
        if (dataHolder.dataHolderBrandId === consent.dataHolderBrandId) {
          setDataHolderLogoUrl(dataHolder.logoUri);
        }
      });
    }
  }, [consent, dataHolders]);

  const handleRevokeDialogClose = () => {
    setOpenConfirmationDialog(false);
  };

  const handleRevokeButtonClick = () => {
    setOpenConfirmationDialog(true);
  };

  const handleRevokeDialogButtonClick = () => {
    setIsProcessingRevoke(true);

    consentRepository
      .revokeConsent(consentId)
      .then((revokedConsent) => {
        setConsent(revokedConsent);
        setUseCasetTitle(pastUseCasetTitle);
        setOpenConfirmationDialog(false);
        setIsProcessingRevoke(false);
      })
      .catch((error) => {
        Logger.error('Error handling Revoke', error);
        setOpenConfirmationDialog(false);
        setIsProcessingRevoke(false);
      });
  };

  return (
    <>
      {isLoading && (
        <>
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
        </>
      )}
      {!isLoading && (
        <>
          <Box>
            <Details
              consent={consent}
              dataHolderLogoUrl={dataHolderLogoUrl}
              dateTitle={'Key dates'}
              useCasetTitle={useCasetTitle}
              onRevokeClick={handleRevokeButtonClick}
              isExtendable={APP_SETTINGS.consent.enableExtendConsent}
              extendableUrl={URL_SETTINGS.CONSENT_EXTEND_DATE.url.replace(':consentId', consent.consentId)}
            />
          </Box>

          <RevokeDialog
            isOpen={openConfirmationDialog}
            isLoading={isProcessingRevoke}
            dataHolderName={consent.dataHolderName}
            onCancelClick={handleRevokeDialogClose}
            onRevokeClick={handleRevokeDialogButtonClick}
          />
        </>
      )}
    </>
  );
};
