import { ErrorMessage } from '@adatree-oss/atomic-components';
import { State } from '../../../app/state/state';
import { useHistory } from 'react-router-dom';
import { URL_SETTINGS } from '../../../app/settings/url.settings';
import { useEffect, useState } from 'react';
import { AppError } from '../../../utils/errors/errors.util';

export const ErrorPage = () => {
  const history = useHistory();
  const [error, setError] = useState<AppError>();

  useEffect(() => {
    const error = State.getError();

    if (error === null) {
      history.push(URL_SETTINGS.CONSENT_LIST.url);
    }

    setError(State.getError());
    State.setError(null);
  }, [history]);

  return (
    <>
      {error && (
        <ErrorMessage
          message={error.message}
          code={error.code}
          data={error.data}
          timeStamp={error.timeStamp}
          type={error.type}
          url={error.url}
        />
      )}
    </>
  );
};
