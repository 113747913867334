export type UrlItem = {
  label: string;
  url: string;
};

export const URL_SETTINGS = {
  AUTH_CALLBACK: { label: 'Authentication callback', url: '/authentication' },
  CONSENT_CALLBACK: { label: 'Consent callback', url: '/redirect' },
  CONSENT_CREATE: { label: 'Create consent', url: '/create' },
  CONSENT_DETAIL: { label: 'Consent', url: '/consent/:consentId' },
  CONSENT_EXTEND_DATE: { label: 'Extend consent date', url: '/consent/:consentId/extend' },
  CONSENT_LIST: { label: 'Manage consents', url: '/' },
  ERROR: { label: 'Error', url: '/error' },
  LOGOUT: { label: 'Logout', url: '/logout' },
};
