import { Container, Stack } from '@mui/material';
import { UrlItem } from '../../app/settings/url.settings';
import { Breadcrumb } from './breadcrumb/breadcrumb.atom';

interface PageWrapperProps {
  children: React.ReactNode;
  breadcrumbs?: UrlItem[];
  pageAction?: React.ReactNode;
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export const PageWrapper = (props: PageWrapperProps) => {
  const { children, breadcrumbs, pageAction, width = 'md' } = props;

  return (
    <>
      <main style={{ margin: '0 1rem' }}>
        <Container
          maxWidth={width}
          sx={{
            my: { xs: 2, md: 2, lg: 3 },
            px: { xs: 2, md: 4, lg: 5 },
            pb: 2,
            pt: 2,
            backgroundColor: 'pageBackground.light',
            borderRadius: '4px',
            position: 'relative',
            minHeight: '1px',
          }}
        >
          {breadcrumbs && (
            <Stack
              sx={{ pt: 1, pb: 3, alignItems: 'center' }}
              direction="row"
              spacing={{ xs: 1 }}
              justifyContent="space-between"
              minHeight={'72px'}
            >
              <Breadcrumb breadcrumbs={breadcrumbs} />
              {pageAction}
            </Stack>
          )}
          {children}
        </Container>
      </main>
    </>
  );
};
