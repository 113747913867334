import React from 'react';
import { Pages, PageMeta } from './pages';
import { Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './private-route';
import { ErrorBoundary } from '../error/error-boundary';

type RenderPageProps = {
  page: PageMeta;
};

const RenderPage = (props: RenderPageProps) => {
  const { page } = props;

  return (
    <>
      <ErrorBoundary>
        {page.isPrivate && <PrivateRoute>{page.component}</PrivateRoute>}
        {!page.isPrivate && <>{page.component}</>}
      </ErrorBoundary>
    </>
  );
};

export const Routes = () => {
  return (
    <Switch>
      {Pages.map((page: PageMeta) => (
        <Route exact path={page.path} key={page.path}>
          <RenderPage page={page} />
        </Route>
      ))}
    </Switch>
  );
};
