import React from 'react';
import { useAuth } from 'oidc-react';
import { FeedbackMessage } from '@adatree-oss/atomic-components';
import { isAuthenticated } from '../authentication/authentication';
import { PageWrapper } from '../../components/atoms/page-wrapper.atom';

interface PrivateRouteProps {
  children: React.ReactNode;
}

export const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const auth = useAuth();
  const signIn = () => {
    if (auth) auth.signIn();
  };

  if (isAuthenticated()) {
    return <>{children}</>;
  } else {
    return (
      <>
        <PageWrapper>
          <FeedbackMessage message="Please wait while we send you to your authentication provider." showSpinner />
        </PageWrapper>
        {signIn()}
      </>
    );
  }
};
