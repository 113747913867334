import { FeedbackMessage } from '@adatree-oss/atomic-components';
import { Link, Typography } from '@mui/material';
import Check from 'mdi-material-ui/Check';
import { URL_SETTINGS } from '../../../app/settings/url.settings';

export const Logout = () => {
  return (
    <FeedbackMessage
      message="You have been successfully logged out."
      icon={<Check sx={{ fontSize: '56px', color: 'primary.main' }} />}
    >
      <Typography>
        Click <Link href={URL_SETTINGS.CONSENT_LIST.url}>here</Link> to log back in.
      </Typography>
    </FeedbackMessage>
  );
};
