import ConsentRepository from '../../types/consent-repository';
import {
  TestUtil,
  ConsentResponse,
  CreateConsent,
  UpdateConsent,
  Status,
  DataHolder,
  UseCaseResponse,
  Logger,
} from '@adatree-oss/atomic-components';
import { v4 as uuidv4 } from 'uuid';
import { APP_SETTINGS } from '../../../settings/app.settings';

function resolveWithFakeNetworkLatency(response: ConsentResponse): Promise<ConsentResponse> {
  const NETWORK_LATENCY = Number(process.env.REACT_APP_FAKE_NETWORK_LATENCY) || 0;
  return new Promise((resolve) => setTimeout(() => resolve(response), NETWORK_LATENCY));
}

function resolveArrayWithFakeNetworkLatency(response: any[]): Promise<any[]> {
  const NETWORK_LATENCY = Number(process.env.REACT_APP_FAKE_NETWORK_LATENCY) || 0;
  return new Promise((resolve) => setTimeout(() => resolve(response), NETWORK_LATENCY));
}

class FakeConsentRepository implements ConsentRepository {
  private readonly consents = TestUtil.testData.consent.all();

  async findAllConsents(): Promise<ConsentResponse[]> {
    return resolveArrayWithFakeNetworkLatency(this.consents);
  }

  createConsent(createConsent: CreateConsent): Promise<ConsentResponse> {
    const consent = TestUtil.testData.consent.generateConsent(createConsent);
    return resolveWithFakeNetworkLatency(consent);
  }

  updateConsent(consentId: string, updateConsent: UpdateConsent): Promise<ConsentResponse> {
    const consent = this.consents.find((i) => i.consentId === consentId);

    if (updateConsent.directMarketingAllowed !== undefined) {
      consent.directMarketingAllowed = updateConsent.directMarketingAllowed;
    }
    if (updateConsent.postUsageAction) {
      consent.postUsageAction = updateConsent.postUsageAction;
    }
    if (updateConsent.sharingEndDate) {
      consent.sharingEndDate = updateConsent.sharingEndDate;
    }

    return resolveWithFakeNetworkLatency(consent);
  }

  revokeConsent(consentId: string): Promise<ConsentResponse> {
    return this.findConsentById(consentId).then((revokedConsent) => {
      revokedConsent.status = Status.REVOKED;
      revokedConsent.revoked = new Date().toISOString();
      return resolveWithFakeNetworkLatency(revokedConsent);
    });
  }

  findConsentById(consentId: string): Promise<ConsentResponse> {
    const newLocal = this.consents.find((i) => i.consentId === consentId);
    if (newLocal !== undefined) {
      return resolveWithFakeNetworkLatency(newLocal);
    }
    return resolveWithFakeNetworkLatency({});
  }

  async findAllDataHolders(): Promise<DataHolder[]> {
    return resolveArrayWithFakeNetworkLatency(TestUtil.testData.dataHolder.all());
  }

  async findAllUseCases(): Promise<UseCaseResponse[]> {
    return resolveArrayWithFakeNetworkLatency(TestUtil.testData.useCase.all());
  }

  async sleep(milliseconds: number): Promise<string> {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  }

  async processAuthorization(state: string, code: string, idToken: string): Promise<string> {
    Logger.debug('Processing FAKE authorization for: ');
    Logger.debug('state: ', state);
    Logger.debug('code: ', code);
    Logger.debug('id_token: ', idToken);
    await this.sleep(1000);
    return Promise.resolve(state);
  }

  authorization(dataHolderBrandId: string, consentId: string, cdrArrangementId?: string): Promise<string> {
    const state = uuidv4();
    sessionStorage.setItem('client_state', state);
    Logger.debug(state, dataHolderBrandId);
    return Promise.resolve(
      `${
        APP_SETTINGS.api.backendProtocol + APP_SETTINGS.api.backendDomain
      }/infosec/authorization?dataHolderBrandId=${dataHolderBrandId}&consentId=${consentId}&cdrArrangementId=${cdrArrangementId}`
    );
  }
}

export default FakeConsentRepository;
