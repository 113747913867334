import adatree from '../themes/adatree.theme.json';
import covidHotspotAlert from '../themes/covid-hotspot-alert.theme.json';
import iselect from '../themes/iselect.theme.json';
import liberty from '../themes/liberty.theme.json';
import payble from '../themes/payble.theme.json';
import payOk from '../themes/payok.theme.json';
import sherlok from '../themes/sherlok.theme.json';

import { Logger } from '@adatree-oss/atomic-components';
import { SimplePaletteColorOptions } from '@mui/material';

export type ApplicationTheme = {
  colour: {
    primary: SimplePaletteColorOptions;
    secondary: SimplePaletteColorOptions;
    cta: { main: string; text: string };
    background: SimplePaletteColorOptions;
    hover: SimplePaletteColorOptions;
    tooltip: { background: string; border: string; text: string };
    typography: SimplePaletteColorOptions;
  };
  font: {
    fontFamily: string;
    fontFamilyHeadings?: string;
    baseHtmlFontSize: number;
  };
  spacing: {
    scalingFactor: number;
  };
};

const getTheme = (theme: string): ApplicationTheme => {
  switch (theme) {
    case 'adatree.theme.json':
      return adatree;
    case 'covid-hotspot-alert.theme.json':
      return covidHotspotAlert;
    case 'iselect.theme.json':
      return iselect;
    case 'liberty.theme.json':
      return liberty;
    case 'payble.theme.json':
      return payble;
    case 'payok.theme.json':
      return payOk;
    case 'sherlok.theme.json':
      return sherlok;
    default:
      Logger.error('Theme not set. Using default');
      return adatree;
  }
};

export const themeSwitcher = {
  getTheme,
};
