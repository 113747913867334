import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';
import { ThemeOptions } from '@mui/material';

export const themeOverride = (): Partial<ThemeOptions> => {
  const LinkBehavior = React.forwardRef<any, Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }>(
    (props, ref) => {
      const { href, ...other } = props;
      // Map href (MUI) -> to (react-router)
      return <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />;
    }
  );

  return {
    components: {
      MuiLink: {
        defaultProps: {
          component: LinkBehavior,
        } as LinkProps,
      },
      MuiListItemButton: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
      MuiButton: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
      MuiFab: {
        defaultProps: {
          LinkComponent: LinkBehavior,
        },
      },
    },
  };
};
