import {
  ConsentStepperResponsive,
  CreateConsent,
  DataHolder,
  UseCaseResponse,
  DataAccessStep,
  InfoCdrStep,
  InfoHowItWorksStep,
  DataHolderStep,
  ReviewStep,
  useConsentForm,
  FeedbackMessage,
  ConsentResponse,
  Logger,
  UseCaseStep,
  PostUsageAction,
} from '@adatree-oss/atomic-components';
import { ReactNode, useEffect, useState } from 'react';
import { APP_SETTINGS } from '../../../app/settings/app.settings';
import { Box } from '@mui/material';
import { URL_SETTINGS } from '../../../app/settings/url.settings';
import { useAuth } from 'oidc-react';
import AlertCircle from 'mdi-material-ui/AlertCircle';
import Check from 'mdi-material-ui/Check';
import consentRepository from '../../../app/api/repositories/consent-repository';
import { getEmail, getMobileNumber } from '../../../app/authentication/authentication';

export const ConsentWizard = () => {
  const auth = useAuth();
  const [consentForm] = useConsentForm();
  const [useCase, setUseCase] = useState<UseCaseResponse>();
  const [useCases, setUseCases] = useState<UseCaseResponse[]>();
  const [existingConsents, setExistingConsents] = useState<ConsentResponse[]>();
  const [dataHolders, setDataHolders] = useState<DataHolder[]>();
  const [feedback, setFeedback] = useState<ReactNode>();

  Logger.debug('ConsentWizard loaded with auth:', auth);

  /**
   * Call the APIs
   */

  useEffect(() => {
    consentRepository
      .findAllUseCases()
      .then((foundUseCases: UseCaseResponse[]) => {
        setUseCases(foundUseCases);
      })
      .catch((error) => {
        // This error needs to be handled
        Logger.error('Error calling useCaseRepository.findAll()');
        throw new Error(error);
      });
  }, []);

  useEffect(() => {
    consentRepository
      .findAllDataHolders()
      .then((dataHolders: DataHolder[]) => {
        setDataHolders(dataHolders);
      })
      .catch((error) => {
        // This error needs to be handled
        Logger.error('Error calling dataHolderRepository.findAll()');
        throw new Error(error);
      });
  }, []);

  useEffect(() => {
    consentRepository
      .findAllConsents()
      .then((foundConsents: ConsentResponse[]) => {
        setExistingConsents(foundConsents);
      })
      .catch((error) => {
        // This error needs to be handled
        Logger.error('Error calling consentRepository.findAll()');
        throw new Error(error);
      });
  }, []);

  /**
   * Submit the form
   */

  const handleSubmitForm = () => {
    let email = getEmail(auth);
    let mobileNumber = getMobileNumber(auth);

    if (email === '' && mobileNumber === '') {
      Logger.error('User email and mobile not set');
    }

    const consent: CreateConsent = {
      consumerEmail: email,
      consumerMobileNumber: mobileNumber,
      dataHolderBrandId: consentForm.dataHolder.dataHolderBrandId,
      directMarketingAllowed: false,
      postUsageAction: PostUsageAction.DELETION,
      sharingEndDate: consentForm.sharingEndDate.toISOString(),
      useCaseId: consentForm.useCaseId,
    };

    const createdConsent = async () => {
      try {
        renderFeedback('Saving your consent request...', true);
        const createdConsent = await consentRepository.createConsent(consent);

        renderFeedback(`Please wait while we redirect you to ${consentForm.dataHolder.brandName}`);
        const redirect = await consentRepository.authorization(
          createdConsent.dataHolderBrandId,
          createdConsent.consentId
        );

        window.location.href = redirect;
      } catch (error) {
        renderFeedback('Sorry we were not able to process your request. Please try again later.', false, true);
      }
    };

    if (APP_SETTINGS.application.simulateBackend) {
      alert('You are simulating the backend. Cannot create a new Consent at this time');
    } else {
      createdConsent();
    }
  };

  /**
   * Configure the steps
   */

  // Each index in the array corresponds to a step
  const [disableNextButtons, setDisableNextButtons] = useState([false, true, true, true, false]);

  const steps = [
    {
      label: 'How it works',
      content: (
        <>
          <InfoHowItWorksStep />
          <Box sx={{ mt: 4 }}>
            <InfoCdrStep
              companyName={APP_SETTINGS.tenant.name}
              accreditationNumber={APP_SETTINGS.tenant.accreditationNumber}
            />
          </Box>
        </>
      ),
      disableNextButton: disableNextButtons[0],
    },
    {
      label: 'Use case',
      content: (
        <UseCaseStep
          useCases={useCases}
          isLoading={!useCases}
          isValid={(isValid) => {
            disableNextButtons[1] = !isValid;
            setDisableNextButtons([...disableNextButtons]);
            if (isValid) {
              setUseCase(
                useCases.find((useCase) => {
                  return useCase.id === consentForm.useCaseId;
                })
              );
            }
          }}
        />
      ),
      disableNextButton: disableNextButtons[1],
    },
    {
      label: 'Data',
      content: (
        <DataAccessStep
          companyName={APP_SETTINGS.tenant.name}
          useCase={useCase}
          isValid={(isValid) => {
            disableNextButtons[2] = !isValid;
            setDisableNextButtons([...disableNextButtons]);
          }}
        />
      ),
      disableNextButton: disableNextButtons[2],
    },
    {
      label: 'Connect',
      content: (
        <DataHolderStep
          dataHolders={dataHolders}
          isValid={(isValid) => {
            disableNextButtons[3] = !isValid;
            setDisableNextButtons([...disableNextButtons]);
          }}
          existingConsents={existingConsents}
          useCase={useCase}
          consentUrl={URL_SETTINGS.CONSENT_LIST.url}
        />
      ),
      disableNextButton: disableNextButtons[3],
    },
    {
      label: 'Summary',
      content: (
        <ReviewStep
          useCase={useCase}
          cdrPolicyUrl={APP_SETTINGS.tenant.cdrPolicyUrl}
          dataSharingRevocationEmail={APP_SETTINGS.tenant.dataSharingRevocationEmail}
        />
      ),
      nextButtonLabel: 'Consent',
      disableNextButton: disableNextButtons[4],
      onNext: handleSubmitForm,
    },
  ];

  const renderFeedback = (message: string, isLoading = false, isError = false) => {
    const icon =
      isError === true ? (
        <AlertCircle sx={{ fontSize: '56px', color: 'error.main' }} />
      ) : (
        <Check sx={{ fontSize: '56px', color: 'primary.main' }} />
      );

    setFeedback(<FeedbackMessage message={message} icon={icon} showSpinner={isLoading} />);
  };

  return (
    <Box>
      {feedback && feedback}
      {!feedback && <ConsentStepperResponsive steps={steps} />}
    </Box>
  );
};
