import * as React from 'react';
import { Container, Typography } from '@mui/material';
import { APP_SETTINGS } from '../../../app/settings/app.settings';

export const Footer = () => {
  return (
    <>
      <Container sx={{ my: 1, textAlign: 'center' }}>
        <Typography sx={{ color: 'pageBackground.contrastText' }}>
          Copyright © {APP_SETTINGS.tenant.name} 2022.
        </Typography>
      </Container>
    </>
  );
};
