import { Logger } from '@adatree-oss/atomic-components';
import { setApiInterceptors } from '../../../app/api/api';
import { APP_SETTINGS } from '../../../app/settings/app.settings';
import { validateSettings } from '../../../app/settings/validator.settings';
import { initState } from '../../../app/state/state';
import { History } from 'history';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

export const bootstrapApp = (history: History): void => {
  printAppStatus();
  validateSettings();
  initBugTracking();
  setApiInterceptors(history);
  initState();
};

const printAppStatus = () => {
  let simulatedItems = '';
  simulatedItems = APP_SETTINGS.application.simulateAuthentication
    ? simulatedItems + 'authentication,'
    : simulatedItems;
  simulatedItems = APP_SETTINGS.application.simulateBackend ? simulatedItems + ' backend' : simulatedItems;

  Logger.info('App version', APP_SETTINGS.application.version, APP_SETTINGS.application.timestamp);

  if (simulatedItems) {
    Logger.info(`App running with simulated ${simulatedItems}`);
  }

  Logger.debug('App ruuning with debug enabled');
  Logger.debug('App settings', APP_SETTINGS);
};

const initBugTracking = () => {
  Bugsnag.start({
    apiKey: APP_SETTINGS.bugsnag.apiKey,
    appVersion: APP_SETTINGS.application.version,
    releaseStage: `${APP_SETTINGS.tenant.name.replaceAll(' ', '-')}-${APP_SETTINGS.stage}`.toLowerCase(),
    plugins: [new BugsnagPluginReact()],
  });
};
