import * as React from 'react';
import { Box } from '@mui/material';
import { LogoutButton } from '../../atoms/logout-button/logout-button.atom';
import { useAuth } from 'oidc-react';
import { isAuthenticated } from '../../../app/authentication/authentication';
import { Link } from 'react-router-dom';
import { APP_SETTINGS } from '../../../app/settings/app.settings';

export const Header = () => {
  const auth = useAuth();

  const handleLogout = () => {
    if (auth) {
      auth.userManager.removeUser();
      auth.userManager.signoutRedirect();
    }
  };

  return (
    <>
      <div>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'end' }}>
          {isAuthenticated() && <LogoutButton handleClick={handleLogout} />}
        </Box>
        <Box sx={{ mt: { xs: 2, md: 0 }, display: 'flex', justifyContent: 'center' }}>
          <Link to="/">
            <img style={{ maxWidth: '30rem' }} src={APP_SETTINGS.tenant.logo} alt="Logo" />
          </Link>
        </Box>
      </div>
    </>
  );
};
