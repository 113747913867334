import { ThemeProvider } from '@adatree-oss/atomic-components';
import { Router } from 'react-router-dom';
import { Routes } from './routes/routes';
import { createBrowserHistory, History } from 'history';
import { Header } from '../components/organisms/navigation/header.organism';
import { themeOverride } from './theme/theme-override';
import { AuthenticationProvider } from '../providers/authentication.provider';
import { bootstrapApp } from '../components/organisms/bootstrap/bootstrap.organism';
import { theme } from './theme/theme';
import { useEffect } from 'react';
import { Footer } from '../components/organisms/navigation/footer.organism';
import { Box } from '@mui/material';
import './theme/fonts/font.css';

export const history: History = createBrowserHistory();

function App() {
  bootstrapApp(history);

  useEffect(() => {
    document.body.style.background = theme.palette.pageBackground.main;
    document.body.style.backgroundColor = theme.palette.pageBackground.dark;
    document.body.style.minHeight = '100vh';
  }, []);

  const extendTheme = themeOverride();

  return (
    <>
      <Router history={history}>
        <AuthenticationProvider>
          <ThemeProvider extendTheme={{ ...theme, ...extendTheme }}>
            <Box
              sx={{
                display: 'grid',
                gridTemplateRows: 'auto 1fr auto',
                minHeight: '100vh',
              }}
            >
              <Header />
              <Routes />
              <Footer />
            </Box>
          </ThemeProvider>
        </AuthenticationProvider>
      </Router>
    </>
  );
}

export default App;
