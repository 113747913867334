import FakeConsentRepository from './fake-consent-repository';
import BackendConsentRepository from './backend-consent-repository';
import ConsentRepository from '../../types/consent-repository';
import { Logger } from '@adatree-oss/atomic-components';
import { APP_SETTINGS } from '../../../settings/app.settings';

let repository: ConsentRepository = new BackendConsentRepository();
if (APP_SETTINGS.application.simulateBackend) {
  Logger.info('========== Simulating consents calls ==========');
  repository = new FakeConsentRepository();
}

export default repository;
