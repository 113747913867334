import {
  ConsentStepperResponsive,
  UpdateConsent,
  ConsentResponse,
  InfoCdrStep,
  ReviewStep,
  useConsentForm,
  FeedbackMessage,
  Logger,
  InfoExtendDateStep,
  UseCaseSummary,
  ExtendConsentDateStep,
} from '@adatree-oss/atomic-components';
import { ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { APP_SETTINGS } from '../../../app/settings/app.settings';
import { Box, Skeleton } from '@mui/material';
import AlertCircle from 'mdi-material-ui/AlertCircle';
import Check from 'mdi-material-ui/Check';
import consentRepository from '../../../app/api/repositories/consent-repository';

type UrlParams = {
  consentId: string;
};

export const ExtendConsentDateWizard = () => {
  const { consentId } = useParams<UrlParams>();
  const [consentForm, setConsentForm] = useConsentForm();
  const [consent, setConsent] = useState<ConsentResponse>();
  const [feedback, setFeedback] = useState<ReactNode>();
  const [isLoading, setIsLoading] = useState(true);

  /**
   * Call the APIs
   */

  useEffect(() => {
    consentRepository
      .findConsentById(consentId)
      .then((foundConsent: ConsentResponse) => {
        consentForm.dataHolder = {
          brandName: foundConsent.dataHolderName,
          dataHolderBrandId: foundConsent.dataHolderBrandId,
          logoUri: '',
        };
        consentForm.postUsageAction = foundConsent.postUsageAction;
        consentForm.useCaseId = foundConsent.useCase.id;
        setConsentForm({ ...consentForm });
        setConsent(foundConsent);
        setIsLoading(false);
      })
      .catch((error) => {
        // This error needs to be handled
        Logger.error('Error calling useCaseRepository.findConsentById()');
        throw new Error(error);
      });
  }, [consentId, setConsentForm, consentForm]);

  /**
   * Submit the form
   */

  const handleSubmitForm = () => {
    const consent: UpdateConsent = {
      sharingEndDate: consentForm.sharingEndDate.toISOString(),
    };

    const updateConsent = async () => {
      try {
        renderFeedback('Saving your consent request...', true);
        const updatedConsent = await consentRepository.updateConsent(consentId, consent);

        renderFeedback(`Please wait while we redirect you to ${consentForm.dataHolder.brandName}`);
        const redirect = await consentRepository.authorization(
          updatedConsent.dataHolderBrandId,
          updatedConsent.consentId
        );

        window.location.href = redirect;
      } catch (error) {
        renderFeedback('Sorry we were not able to process your request. Please try again later.', false, true);
      }
    };

    if (APP_SETTINGS.application.simulateBackend) {
      alert('You are simulating the backend. Cannot update the Consent at this time');
    } else {
      updateConsent();
    }
  };

  /**
   * Configure the steps
   */

  // Each index in the array corresponds to a step
  const [disableNextButtons, setDisableNextButtons] = useState([false, true, false]);

  const getSteps = () => {
    return [
      {
        label: 'How it works',
        content: (
          <>
            <InfoExtendDateStep />
            <InfoCdrStep
              companyName={APP_SETTINGS.tenant.name}
              accreditationNumber={APP_SETTINGS.tenant.accreditationNumber}
            />
          </>
        ),
        disableNextButton: disableNextButtons[0],
      },
      {
        label: 'Update',
        content: (
          <>
            <UseCaseSummary title={'Update sharing expiration date'} useCase={consent.useCase} />
            <ExtendConsentDateStep
              companyName={APP_SETTINGS.tenant.name}
              useCase={consent.useCase}
              isValid={(isValid) => {
                disableNextButtons[1] = !isValid;
                setDisableNextButtons([...disableNextButtons]);
              }}
            />
          </>
        ),
        disableNextButton: disableNextButtons[1],
      },
      {
        label: 'Review',
        content: (
          <>
            <ReviewStep
              useCase={consent.useCase}
              cdrPolicyUrl={APP_SETTINGS.tenant.cdrPolicyUrl}
              dataSharingRevocationEmail={APP_SETTINGS.tenant.dataSharingRevocationEmail}
            />
          </>
        ),
        nextButtonLabel: 'Update',
        disableNextButton: disableNextButtons[2],
        onNext: handleSubmitForm,
      },
    ];
  };

  const renderFeedback = (message: string, isLoading = false, isError = false) => {
    const icon =
      isError === true ? (
        <AlertCircle sx={{ fontSize: '56px', color: 'error.main' }} />
      ) : (
        <Check sx={{ fontSize: '56px', color: 'primary.main' }} />
      );

    setFeedback(<FeedbackMessage message={message} icon={icon} showSpinner={isLoading} />);
  };

  return (
    <>
      {isLoading && (
        <>
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
        </>
      )}
      {!isLoading && consent && (
        <Box>
          {feedback && feedback}
          {!feedback && <ConsentStepperResponsive steps={getSteps()} />}
        </Box>
      )}
    </>
  );
};
