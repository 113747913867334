import { Logger } from '@adatree-oss/atomic-components';
import { APP_SETTINGS } from './app.settings';
import { ApiSettings, ApplicationSettings, OidcSettings, TenantSettings } from './app.settings.types';

export const validateSettings = (): void => {
  validate(APP_SETTINGS.api, 'API');
  validate(APP_SETTINGS.application, 'Applicatiom');
  validate(APP_SETTINGS.oidc, 'OIDC');
  validate(APP_SETTINGS.tenant, 'Tenant');
};

const validate = (
  settings: ApiSettings | ApplicationSettings | OidcSettings | TenantSettings,
  settingName: string
): void => {
  try {
    if (isNotSet(settings, settingName)) {
      throw new Error('Settings error');
    }
  } catch (error) {
    const errorMessage = 'Missing Settings configuration - Application may not work as expected.';
    Logger.error(errorMessage);
    throw new Error(errorMessage);
  }
};

const isNotSet = (
  settingObject: ApiSettings | ApplicationSettings | OidcSettings | TenantSettings,
  settingName: string
): boolean => {
  let invalidSettings = false;

  for (const [key, value] of Object.entries(settingObject)) {
    if (value === undefined || value === null) {
      invalidSettings = true;
      Logger.error(`${settingName} settings - ${key} is not set`);
    }
  }

  return invalidSettings;
};
