import { useEffect, useState } from 'react';
import { ConsentTabs, ConsentResponse, DataHolder } from '@adatree-oss/atomic-components';
import { Box, Typography } from '@mui/material';
import consentRepository from '../../../app/api/repositories/consent-repository';
import { APP_SETTINGS } from '../../../app/settings/app.settings';

export const ConsentList = () => {
  const [consents, setConsents] = useState<ConsentResponse[]>();
  const [dataHolders, setDataHolder] = useState<DataHolder[]>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    consentRepository.findAllConsents().then((consentResponses: ConsentResponse[]) => {
      setConsents(consentResponses);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    consentRepository.findAllDataHolders().then((dataHolders: DataHolder[]) => {
      setDataHolder(dataHolders);
    });
  }, []);

  return (
    <Box>
      <ConsentTabs consents={consents} isLoading={isLoading} dataHolders={dataHolders} />
      <Typography variant="body2" sx={{ mt: 4 }}>
        Please read our{' '}
        <a href={APP_SETTINGS.tenant.cdrPolicyUrl} target="_blank" rel="noreferrer">
          Consumer Data Right Policy
        </a>{' '}
        for more information on how {APP_SETTINGS.tenant.name} handles your data.
      </Typography>
    </Box>
  );
};
