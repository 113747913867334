import React, { ReactNode } from 'react';
import { AuthProvider } from 'oidc-react';
import { useHistory } from 'react-router-dom';
import { initAuthentication } from '../app/authentication/authentication';
import { URL_SETTINGS } from '../app/settings/url.settings';
import { APP_SETTINGS } from '../app/settings/app.settings';

interface Props {
  children: ReactNode;
}

export const AuthenticationProvider: React.FC<Props> = ({ children }: Props) => {
  const history = useHistory();
  const authConfig = initAuthentication();

  const oidcConfig = {
    userManager: authConfig,
    autoSignIn: false,
    onSignIn: async () => {
      if (APP_SETTINGS.consent.disableCreateConsent) {
        history.push(URL_SETTINGS.CONSENT_LIST.url);
      } else {
        history.push(URL_SETTINGS.CONSENT_CREATE.url);
      }
    },
  };

  return <AuthProvider {...oidcConfig}>{children}</AuthProvider>;
};
