import { ConsentFormProvider } from '@adatree-oss/atomic-components';
import { ReactChild } from 'react';
import { PageWrapper } from '../../components/atoms/page-wrapper.atom';
import { URL_SETTINGS } from '../settings/url.settings';
import { ConsentWizard } from '../../components/organisms/consent-wizard/consent-wizard.organism';
import { ConsentList } from '../../components/organisms/consent-list/consent-list.organism';
import { ConsentDetails } from '../../components/organisms/consent-details/consent-details.organism';
import { Fab } from '@mui/material';
import { Logout } from '../../components/organisms/logout/logout.organism';
import { APP_SETTINGS } from '../settings/app.settings';
import { ExtendConsentDateWizard } from '../../components/organisms/extend-consent-date-wizard/extend-consent-date-wizard.organism';
import { ErrorPage } from '../../components/organisms/error/error.organism';
import { ConsentCallback } from '../../components/organisms/consent-callback/consent-callback.organism';
import { AuthenticationCallback } from '../../components/organisms/authentication-callback/authentication-callback.organism';
import PlusIcon from 'mdi-material-ui/Plus';

export type PageMeta = {
  path: string;
  component: ReactChild;
  isPrivate: boolean;
};

export type PageDocMeta = {
  path: string;
  mdxComponent: ReactChild;
};

const GeneralPages: PageMeta[] = [
  {
    path: URL_SETTINGS.ERROR.url,
    component: (
      <PageWrapper>
        <ErrorPage />
      </PageWrapper>
    ),
    isPrivate: false,
  },
];

const AuthenticationPages: PageMeta[] = [
  {
    path: URL_SETTINGS.LOGOUT.url,
    component: (
      <PageWrapper>
        <Logout />
      </PageWrapper>
    ),
    isPrivate: false,
  },
  {
    path: URL_SETTINGS.AUTH_CALLBACK.url,
    component: (
      <PageWrapper>
        <AuthenticationCallback />
      </PageWrapper>
    ),
    isPrivate: false,
  },
];

const ConsentPages: PageMeta[] = [
  {
    path: URL_SETTINGS.CONSENT_CALLBACK.url,
    component: (
      <PageWrapper>
        <ConsentCallback />
      </PageWrapper>
    ),
    isPrivate: true,
  },
  {
    path: URL_SETTINGS.CONSENT_CREATE.url,
    component: (
      <PageWrapper breadcrumbs={[URL_SETTINGS.CONSENT_LIST, URL_SETTINGS.CONSENT_CREATE]}>
        <ConsentFormProvider>
          <ConsentWizard />
        </ConsentFormProvider>
      </PageWrapper>
    ),
    isPrivate: true,
  },
  {
    path: URL_SETTINGS.CONSENT_DETAIL.url,
    component: (
      <PageWrapper breadcrumbs={[URL_SETTINGS.CONSENT_LIST, URL_SETTINGS.CONSENT_DETAIL]}>
        <ConsentDetails />
      </PageWrapper>
    ),
    isPrivate: true,
  },
  {
    path: URL_SETTINGS.CONSENT_EXTEND_DATE.url,
    component: (
      <PageWrapper
        breadcrumbs={[URL_SETTINGS.CONSENT_LIST, URL_SETTINGS.CONSENT_DETAIL, URL_SETTINGS.CONSENT_EXTEND_DATE]}
      >
        <ConsentFormProvider>
          <ExtendConsentDateWizard />
        </ConsentFormProvider>
      </PageWrapper>
    ),
    isPrivate: true,
  },
  {
    path: URL_SETTINGS.CONSENT_LIST.url,
    component: (
      <PageWrapper
        breadcrumbs={[URL_SETTINGS.CONSENT_LIST]}
        pageAction={
          <>
            {!APP_SETTINGS.consent.disableCreateConsent && (
              <Fab href={URL_SETTINGS.CONSENT_CREATE.url} color="cta" aria-label="Create consent" size="small">
                <PlusIcon />
              </Fab>
            )}
          </>
        }
      >
        <ConsentList />
      </PageWrapper>
    ),
    isPrivate: true,
  },
];

export const Pages: PageMeta[] = [...GeneralPages, ...AuthenticationPages, ...ConsentPages];
