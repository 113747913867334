import { State } from '../../app/state/state';

export type AppError = {
  message: string;
  code?: string;
  data?: object;
  type?: string;
  timeStamp?: Date;
  url?: string;
  supportContact?: string;
};

export const handleError = (type: string, error: any) => {
  const appError: AppError = parseError(type, error);
  State.setError(appError);
};

export const parseError = (type: string, error: any) => {
  const appError: AppError = { message: '', timeStamp: new Date(), type: type };

  if (error.config && error.config.url) {
    appError.url = error.config.url;
  }

  if (error.message) {
    appError.message = error.message;
  }

  if (error.name) {
    appError.code = error.name;
  }

  if (error.stack) {
    appError.data = error.stack;
  }

  if (error.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    appError.code = '' + error.response.status;
    appError.data = error.response.data;
  } else if (error.request) {
    // The request was made but no response was received `error.request` is an instance of XMLHttpRequest
    appError.code = '' + error.request.status;
    appError.message = error.request.statusText !== '' ? error.request.statusText : appError.message;
  }

  return appError;
};
