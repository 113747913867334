import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FeedbackMessage, Logger } from '@adatree-oss/atomic-components';
import { URL_SETTINGS } from '../../../app/settings/url.settings';
import queryString from 'query-string';
import AlertCircle from 'mdi-material-ui/AlertCircle';
import consentRepository from '../../../app/api/repositories/consent-repository';

const STATE_KEY = 'client_state';

export const ConsentCallback: React.FC = () => {
  const history = useHistory();
  const { hash } = useLocation();
  const [isProcessing, setIsProcessing] = useState(true);

  const queryParams = queryString.parse(hash);
  const code: string = queryParams.code as string;
  const idToken: string = queryParams.id_token as string;
  const state: string = queryParams.state as string;

  useEffect(() => {
    const localState = sessionStorage.getItem(STATE_KEY);
    const currentUri = window.location.origin + window.location.pathname;
    let localRedirectUri;

    if (localState) localRedirectUri = sessionStorage.getItem(localState);
    Logger.debug(localState, localRedirectUri, state, code, idToken);

    if (localState !== state && localRedirectUri !== currentUri) {
      setIsProcessing(false);
      Logger.error(
        `CSRF check failed. Local state ${localState} and local URI ${localRedirectUri} do not match callback state ${state} and callback URI ${currentUri}`
      );
    }

    if (code && idToken && state) {
      consentRepository
        .processAuthorization(state, code, idToken)
        .then((id: string) => {
          if (localState) sessionStorage.removeItem(localState);
          sessionStorage.removeItem(STATE_KEY);
          history.push(URL_SETTINGS.CONSENT_DETAIL.url.replace(':consentId', id));
        })
        .catch((error) => {
          setIsProcessing(false);
          Logger.error('Error calling processAuthorization', error);
        });
    } else {
      setIsProcessing(false);
      Logger.error('Consent Callback did not received all the required hash parameters:');
    }
  }, [code, idToken, state, history]);

  return (
    <>
      {isProcessing && <FeedbackMessage message="Processing your response..." showSpinner={true} />}
      {!isProcessing && (
        <FeedbackMessage
          message="Sorry we were not able to process your request. Please try again later."
          icon={<AlertCircle sx={{ fontSize: '56px', color: 'error.main' }} />}
        />
      )}
    </>
  );
};
