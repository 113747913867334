import { AxiosResponse } from 'axios';
import {
  ConsentResponse,
  CreateConsent,
  UpdateConsent,
  ConsentApiFactory,
  DataHolder,
  DataHoldersApiFactory,
  UseCaseApiFactory,
  UseCaseResponse,
  Logger,
  TokensApiFactory,
  AuthorizationApiFactory,
  Authorization,
  Configuration,
  ConfigurationParameters,
} from '@adatree-oss/atomic-components';
import { v4 as uuidv4 } from 'uuid';
import ConsentRepository from '../../types/consent-repository';
import { APP_SETTINGS } from '../../../settings/app.settings';
import { getAccessToken } from '../../../authentication/authentication';
// import { OIDC_SETTINGS } from '../../../settings/oidc.settings';

class BackendConsentRepository implements ConsentRepository {
  urlBase: string;
  consentConfig: Configuration;
  dataHolderConfig: Configuration;
  STATE_KEY = 'client_state';

  constructor() {
    this.urlBase = `${APP_SETTINGS.api.backendProtocol}://${APP_SETTINGS.api.backendDomain}`;

    const consentConfigParameters: ConfigurationParameters = {
      basePath: this.urlBase,
      baseOptions: {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    };

    const dataHoldersConfigParameters: ConfigurationParameters = {
      basePath: this.urlBase,
      baseOptions: {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    };

    this.consentConfig = new Configuration(consentConfigParameters);
    this.dataHolderConfig = new Configuration(dataHoldersConfigParameters);
  }

  async findAllConsents(): Promise<ConsentResponse[]> {
    this.consentConfig.accessToken = await getAccessToken();
    const response = await ConsentApiFactory(this.consentConfig, this.urlBase).findAllConsents();
    const consents: ConsentResponse[] = response.data;
    return consents;
  }

  async findAllDataHolders(): Promise<DataHolder[]> {
    this.dataHolderConfig.accessToken = await getAccessToken();
    const response = await DataHoldersApiFactory(this.dataHolderConfig, this.urlBase).getDataHolders(
      APP_SETTINGS.api.defaultSoftwareProductId
    );

    return Promise.resolve(response.data.sort((a, b) => a.brandName.localeCompare(b.brandName)));
  }

  async findAllUseCases(): Promise<UseCaseResponse[]> {
    this.consentConfig.accessToken = await getAccessToken();
    const response = await UseCaseApiFactory(this.consentConfig, this.urlBase).findAllUseCases();

    return Promise.resolve(
      response.data.sort((a, b) => {
        if (a.priority && b.priority) {
          return a.priority - b.priority;
        } else {
          return 0;
        }
      })
    );
  }

  async createConsent(consent: CreateConsent): Promise<ConsentResponse> {
    this.consentConfig.accessToken = await getAccessToken();
    const response: AxiosResponse<ConsentResponse> = await ConsentApiFactory(
      this.consentConfig,
      this.urlBase
    ).createConsent(consent);
    const consentResponse: ConsentResponse = await response.data;
    return consentResponse;
  }

  async updateConsent(consentId: string, consent: UpdateConsent): Promise<ConsentResponse> {
    this.consentConfig.accessToken = await getAccessToken();
    const response = await ConsentApiFactory(this.consentConfig, this.urlBase).updateConsent(consentId, consent);
    if (response.status === 200) {
      return this.findConsentById(consentId);
    }
    return Promise.reject(`Could not update consent. Server responded with: ${response.status}`);
  }

  async revokeConsent(consentId: string): Promise<ConsentResponse> {
    this.consentConfig.accessToken = await getAccessToken();
    const response = await ConsentApiFactory(this.consentConfig, this.urlBase).revokeConsent(consentId);
    if (response.status === 200) {
      return this.findConsentById(consentId);
    }
    return Promise.reject(`Could not update consent. Server responded with: ${response.status}`);
  }

  async findConsentById(consentId: string): Promise<ConsentResponse> {
    this.consentConfig.accessToken = await getAccessToken();
    const response = await ConsentApiFactory(this.consentConfig, this.urlBase).findConsent(consentId);
    const consentResponse: ConsentResponse = await response.data;
    return Promise.resolve(consentResponse);
  }

  async authorization(dataHolderBrandId: string, consentId: string, cdrArrangementId?: string): Promise<string> {
    const state = uuidv4();
    // Store the state and redirect Uri for future security check
    sessionStorage.setItem(this.STATE_KEY, state);
    sessionStorage.setItem(state, APP_SETTINGS.api.adhRedirectUri);

    const parametersConfiguration: ConfigurationParameters = {
      accessToken: await getAccessToken(),
      baseOptions: {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    };
    const configuration: Configuration = new Configuration(parametersConfiguration);
    const response = await AuthorizationApiFactory(configuration, this.urlBase).getAuthorization(
      dataHolderBrandId,
      undefined,
      state,
      undefined,
      consentId,
      cdrArrangementId,
      undefined,
      undefined,
      APP_SETTINGS.api.adhRedirectUri
    );

    const redirect = response.data;

    Logger.debug('AuthorizationApiFactory redirect ', redirect);

    if (response.status === 200) {
      return Promise.resolve(redirect);
    }
    return Promise.reject('Could not process the request');
  }

  async processAuthorization(state: string, code: string, idToken: string): Promise<string> {
    const parametersConfiguration: ConfigurationParameters = {
      accessToken: await getAccessToken(),
      baseOptions: {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    };
    const configuration: Configuration = new Configuration(parametersConfiguration);
    const authorization: Authorization = {
      code,
      state,
      id_token: idToken,
    };

    Logger.debug('Calling ExternalTokensApiFactory from BackendInfoSecService.processAuthorization');

    const response = await TokensApiFactory(configuration, this.urlBase).createToken(authorization);

    Logger.debug('TokensApiFactory response', response, response.data);

    if (response.status === 201) {
      sessionStorage.removeItem(this.STATE_KEY);

      // @ts-ignore
      return Promise.resolve(response.data.activeConsentId);
    }
    Logger.error('About to fail the create credential flow because the response was not 201', response);
    return Promise.reject('Could not process the request');
  }
}

export default BackendConsentRepository;
