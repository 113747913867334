import { Logger } from '@adatree-oss/atomic-components';
import { signout } from '../authentication/authentication';
import { handleError } from '../../utils/errors/errors.util';
import { URL_SETTINGS } from '../settings/url.settings';
import { History } from 'history';
import axios from 'axios';

export const setApiInterceptors = (history: History): void => {
  axios.interceptors.request.use(
    (req) => {
      return req;
    },
    (err) => {
      handleError('Request error', err);
      history.push(URL_SETTINGS.ERROR.url);
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (err) => {
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 401:
            Logger.warn(
              'Server responded with a 401, logging the user out of their Auth Provider since their access token is unauthorized.'
            );
            signout();
            break;
          default:
            handleError('Response error', err);
            history.push(URL_SETTINGS.ERROR.url);
            break;
        }
      } else {
        handleError('Network error', err);
        history.push(URL_SETTINGS.ERROR.url);
      }

      return Promise.reject(err);
    }
  );
};
