import {
  ApiSettings,
  ApplicationSettings,
  BugsnagSettings,
  ConsentSettings,
  OidcSettings,
  TenantSettings,
  TenantStageSettings,
} from './app.settings.types';

const apiSettings: ApiSettings = {
  adhRedirectUri: process.env.REACT_APP_ADH_REDIRECT_URI,
  backendDomain: process.env.REACT_APP_BACKEND_DOMAIN,
  backendProtocol: process.env.REACT_APP_BACKEND_PROTOCOL,
  defaultSoftwareProductId: process.env.REACT_APP_DEFAULT_SOFTWARE_PRODUCT_ID,
};

const applicationSettings: ApplicationSettings = {
  backgroundColour: process.env.REACT_APP_BACKGROUND_COLOUR,
  enableDebug: process.env.REACT_APP_ENABLE_DEBUG === 'true',
  simulateAuthentication: process.env.REACT_APP_SIMULATE_AUTHENTICATION === 'true',
  simulateBackend: process.env.REACT_APP_SIMULATE_BACKEND === 'true',
  theme: process.env.REACT_APP_THEME,
  timestamp: process.env.REACT_APP_TIMESTAMP || 'no-timestamp-set',
  version: process.env.REACT_APP_VERSION || 'no-version-set',
};

const bugsnagSettings: BugsnagSettings = {
  apiKey: '58506f4757433efa3b29a49b67322de5',
};

const consentSettings: ConsentSettings = {
  useCase: process.env.REACT_APP_CONSENT_USE_CASE,
  disableCreateConsent: process.env.REACT_APP_DISABLE_CONSENT_FLOW === 'true',
  enableExtendConsent: process.env.REACT_APP_ENABLE_EXTEND_CONSENT === 'true',
};

const oidcSettings: OidcSettings = {
  authorityUri: process.env.REACT_APP_OIDC_AUTHORITY_URI,
  clientId: process.env.REACT_APP_OIDC_CLIENT_ID,
  endSessionEndpoint: process.env.REACT_APP_OIDC_END_SESSION_ENDPOINT,
  extraQueryParams: process.env.REACT_APP_OIDC_EXTRA_QUERY_PARAMS, // JSON string example '{"audience":"https://some-domain.com/api/v2/"}'
  issuer: process.env.REACT_APP_OIDC_ISSUER,
  jwksUri: process.env.REACT_APP_OIDC_JWKS_URI,
  loadUserInfo: process.env.REACT_APP_OIDC_LOAD_USER_INFO === 'true',
  redirectUri: process.env.REACT_APP_OIDC_REDIRECT_URI,
  responseType: process.env.REACT_APP_OIDC_RESPONSE_TYPE,
  scope: process.env.REACT_APP_OIDC_SCOPE,
  tokenEndpoint: process.env.REACT_APP_OIDC_TOKEN_ENDPOINT,
  userinfoEndpoint: process.env.REACT_APP_OIDC_USERINFO_ENDPOINT,
};

const tenantSettings: TenantSettings = {
  accreditationNumber: process.env.REACT_APP_TENANT_ACCREDITATION_NUMBER,
  cdrPolicyUrl: process.env.REACT_APP_TENANT_CDR_POLICY_URL,
  dataSharingRevocationEmail: process.env.REACT_APP_TENANT_DATA_SHARING_REVOCATION_EMAIL,
  logo: process.env.REACT_APP_TENANT_LOGO,
  name: process.env.REACT_APP_TENANT_NAME,
  website: process.env.REACT_APP_TENANT_WEBSITE,
};

export const APP_SETTINGS: TenantStageSettings = {
  id: '',
  stage: process.env.REACT_APP_STAGE,
  api: apiSettings,
  application: applicationSettings,
  bugsnag: bugsnagSettings,
  consent: consentSettings,
  oidc: oidcSettings,
  tenant: tenantSettings,
};
