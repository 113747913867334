import { AuthContextProps, User, UserManager } from 'oidc-react';
import { State } from '../state/state';
import { Logger } from '@adatree-oss/atomic-components';
import { APP_SETTINGS } from '../settings/app.settings';

export type UserProfile = {
  email?: string;
  email_verified?: boolean;
  name?: string;
  nickname?: string;
  picture?: string;
  phone_number?: string;
  sub?: string;
  updated_at?: string;
};

export const initAuthentication = (): UserManager => {
  const userManager = new UserManager({
    authority: APP_SETTINGS.oidc.authorityUri,
    client_id: APP_SETTINGS.oidc.clientId,
    extraQueryParams: JSON.parse(APP_SETTINGS.oidc.extraQueryParams),
    loadUserInfo: APP_SETTINGS.oidc.loadUserInfo,
    metadata: {
      authorization_endpoint: APP_SETTINGS.oidc.authorityUri,
      end_session_endpoint: APP_SETTINGS.oidc.endSessionEndpoint,
      issuer: APP_SETTINGS.oidc.issuer,
      jwks_uri: APP_SETTINGS.oidc.jwksUri,
      token_endpoint: APP_SETTINGS.oidc.tokenEndpoint,
      userinfo_endpoint: APP_SETTINGS.oidc.userinfoEndpoint,
    },
    redirect_uri: APP_SETTINGS.oidc.redirectUri,
    response_type: APP_SETTINGS.oidc.responseType,
    scope: APP_SETTINGS.oidc.scope,
  });

  State.setAuthentication(userManager);

  return userManager;
};

export const isAuthenticated = (): boolean => {
  let auth = false;
  if (APP_SETTINGS.application.simulateAuthentication) {
    return true;
  }

  const item = sessionStorage.getItem(`oidc.user:${APP_SETTINGS.oidc.authorityUri}:${APP_SETTINGS.oidc.clientId}`);

  if (item) {
    try {
      auth = isAuthValid(item);
    } catch (error) {
      Logger.error('Error parsing authentication data from session storage', error);
    }
  }

  return auth;
};

export const getAccessToken = async (): Promise<string> => {
  const auth = State.getAuthentication();

  if (!auth) {
    return '';
  }

  return auth.getUser().then((user: User | null) => {
    return user ? user.access_token : '';
  });
};

export const getEmail = (auth: AuthContextProps): string => {
  let email = '';

  if (auth && auth.userData && auth.userData.profile) {
    if (auth.userData.profile.email !== undefined) {
      email = auth.userData.profile.email;
    } else if (auth.userData.profile.emails !== undefined) {
      email = auth.userData.profile.emails[0];
    }
  }

  if (email === '') {
    const userProfile = getLocalUserProfile();
    if (userProfile) {
      if (userProfile.email) {
        email = userProfile.email;
      }
    }
  }

  return email;
};

export const getMobileNumber = (auth: AuthContextProps): string => {
  let mobileNumber = '';

  if (auth && auth.userData && auth.userData.profile) {
    if (auth.userData.profile.phone_number !== undefined) {
      mobileNumber = auth.userData.profile.phone_number;
    }
  }

  if (mobileNumber === '') {
    const userProfile = getLocalUserProfile();
    if (userProfile) {
      if (userProfile.phone_number) {
        mobileNumber = userProfile.phone_number;
      }
    }
  }

  return mobileNumber;
};

export const getLocalUserProfile = (): UserProfile | undefined => {
  const item = sessionStorage.getItem(`oidc.user:${APP_SETTINGS.oidc.authorityUri}:${APP_SETTINGS.oidc.clientId}`);

  if (item) {
    try {
      return parseUserProfile(item);
    } catch (error) {
      Logger.error('Error parsing oidc user profile data from session storage', error);
    }
  }
};

const parseUserProfile = (item: string): UserProfile | undefined => {
  const data = JSON.parse(item);
  if (data.profile) {
    return data.profile;
  } else {
    return undefined;
  }
};

const isAuthValid = (item: string): boolean => {
  const data = JSON.parse(item);
  if (data.expires_at) {
    return data && data.access_token && data.expires_at && isValidExpiresAt(data.expires_at);
  } else {
    return data && data.access_token;
  }
};

const isValidExpiresAt = (secondsSinceEpochTime: number): boolean => {
  const nowInSeconds = Math.floor(Date.now() / 1000);
  return nowInSeconds < secondsSinceEpochTime ? true : false;
};

export const signout = async (): Promise<void> => {
  const authentication = State.getAuthentication();

  if (authentication) {
    authentication.removeUser();
    authentication.signoutRedirect();
    return;
  }
};
