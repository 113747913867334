import { darken, lighten, SimplePaletteColorOptions, ThemeOptions } from '@mui/material';
import { APP_SETTINGS } from '../settings/app.settings';
import { themeSwitcher } from './switcher/theme.switcher';

const appTheme = themeSwitcher.getTheme(APP_SETTINGS.application.theme);
const fontFamilyBase = appTheme.font.fontFamily;
let fontFamilyHeadings = appTheme.font.fontFamily;

if (appTheme.font.fontFamilyHeadings) {
  fontFamilyHeadings = appTheme.font.fontFamilyHeadings;
}

const palette = {
  primary: appTheme.colour.primary,
  secondary: appTheme.colour.secondary,
  cta: {
    light: lighten(appTheme.colour.cta.main, 0.3),
    main: appTheme.colour.cta.main,
    dark: darken(appTheme.colour.cta.main, 0.3),
    contrastText: appTheme.colour.cta.text,
  },
  pageBackground: appTheme.colour.background,
  hover: appTheme.colour.hover,
  tooltip: {
    light: appTheme.colour.tooltip.background,
    main: appTheme.colour.tooltip.text,
    dark: appTheme.colour.tooltip.border,
  },
  typography: appTheme.colour.typography,
};

export const theme: Partial<ThemeOptions> = {
  palette: {
    ...palette,
  },
  typography: {
    fontFamily: fontFamilyBase,

    // Set the HTML Base font size (px)
    // Useful to set the 10px simplification, remember to set "font-size:62.5%" on the html element
    // https://www.sitepoint.com/understanding-and-using-rem-units-in-css/
    htmlFontSize: appTheme.font.baseHtmlFontSize,

    allVariants: {
      color: appTheme.colour.typography.main,
    },

    h1: {
      fontFamily: fontFamilyHeadings,

      fontSize: '3rem',
      letterSpacing: '0',
      lineHeight: '1.1',
    },
    h2: {
      fontFamily: fontFamilyHeadings,
      fontSize: '2.2rem',
      fontWeight: 600,
      letterSpacing: '0.07px',
      lineHeight: '1.6',
    },
    h3: {
      fontFamily: fontFamilyHeadings,
      fontSize: '1.6rem',
      fontWeight: 600,
      letterSpacing: '0.07px',
      lineHeight: '1.6',
    },
    h4: {
      fontFamily: fontFamilyHeadings,
      fontSize: '1.6rem',
      fontWeight: 600,
      letterSpacing: '0.07px',
      lineHeight: '1.5',
    },
    body1: {
      fontSize: '1.6rem',
      letterSpacing: '0.1px',
      lineHeight: '1.4',
    },
    body2: {
      fontSize: '1.4rem',
      lineHeight: '1.5',
      letterSpacing: '0.3px',
    },
    subtitle1: {
      fontSize: '1.6rem',
      fontWeight: 500,
      letterSpacing: '0.1px',
      lineHeight: '1.4',
    },
    subtitle2: {
      fontSize: '1.6rem',
      letterSpacing: '0.1px',
      lineHeight: '1.4',
    },
  },

  // Set the spacing scaling factor (px)
  // Uses the baseHtmlFontSize, example
  // ( scalingFactor / baseHtmlFontSize) * factor = X rem
  // ( 8 / 10 ) * 1 = 0.8rem (8px since baseHtmlFontSize is 10)
  // ( 8 / 16 ) * 1 = 0.5rem (8px since baseHtmlFontSize is 16)
  spacing: (factor) => `${((appTheme.spacing.scalingFactor / appTheme.font.baseHtmlFontSize) * 10 * factor) / 10}rem`, // * 10 and / 10 to avoid JS IEEE 754 encoding error
};

declare module '@mui/material/styles' {
  interface Palette {
    pageBackground: SimplePaletteColorOptions;
  }
  interface PaletteOptions {
    pageBackground: SimplePaletteColorOptions;
  }
}

// Update the element's color prop options
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    cta: true;
  }
}

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    cta: true;
  }
}

declare module '@mui/material/Fab' {
  interface FabPropsColorOverrides {
    cta: true;
  }
}

declare module '@mui/material/Radio' {
  interface RadioPropsColorOverrides {
    cta: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    cta: true;
  }
}
