import { UserManager } from 'oidc-react';
import { AppError } from '../../utils/errors/errors.util';

/* Simple state system for the app, can always upgrade to Redux or similar if required */

declare global {
  interface Window {
    adt: AppState;
  }
}

type AppState = {
  authentication: UserManager | null;
  appError: AppError | null;
};

export const initState = (): AppState => {
  window.adt = {
    authentication: null,
    appError: null,
  };
  return window.adt;
};

/** Getters & Setters **/

const getAuthentication = (): UserManager => {
  return window.adt.authentication;
};

const setAuthentication = (authentication: UserManager): void => {
  window.adt.authentication = authentication;
};

const getError = (): AppError => {
  if (window.adt.appError === null) {
    return null;
  }
  const error = {
    ...window.adt.appError,
  };
  return error;
};

const setError = (error: AppError): void => {
  window.adt.appError = error;
};

export const State = {
  getAuthentication,
  setAuthentication,
  getError,
  setError,
};
