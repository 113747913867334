import React from 'react';
import { Button } from '@mui/material';

export type LogoutButtonProps = {
  handleClick: () => void;
};

export const LogoutButton: React.FC<LogoutButtonProps> = (props) => {
  const { handleClick } = props;

  return (
    <Button onClick={handleClick} color="cta" variant="contained">
      Logout
    </Button>
  );
};
